// extracted by mini-css-extract-plugin
export var AuthorAvatarContainer = "Y_c1";
export var AuthorBioContainer = "Y_cZ";
export var AuthorContainer = "Y_cY";
export var AuthorGradientBackground = "Y_c0";
export var AuthorInfoContainer = "Y_c2";
export var AuthorInfoDetails = "Y_c3";
export var AuthorInfoName = "Y_c4";
export var AuthorInfoSection = "Y_c5";
export var MuiPopperRoot = "Y_bB";
export var MuiTooltipPopper = "Y_bC";