// extracted by mini-css-extract-plugin
export var blush = "bz_dZ";
export var blushIllustration = "bz_dR";
export var button = "bz_bG";
export var cardHeadline = "bz_dS";
export var cardRoot = "bz_dQ";
export var cardSubtitle = "bz_dY";
export var resourcesTileMobileVerticalContainer = "bz_dX";
export var rowTwo = "bz_dW";
export var rowtwosignal = "bz_d0";
export var wrapper = "bz_bD";