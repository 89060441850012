// extracted by mini-css-extract-plugin
export var actions = "bD_d4";
export var articleMultipleChoiceCTA = "bD_d8";
export var body = "bD_fb";
export var bodyContainer = "bD_d9";
export var illustration = "bD_fd";
export var selected = "bD_fh";
export var subtitle = "bD_fg";
export var textContent = "bD_ff";
export var tile = "bD_fc";
export var wrapper = "bD_bD";